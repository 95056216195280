<template>
	<div class="page-container page_regi">
		<!-- <RegiHead /> -->
		<div class="regi-bd change_bd">
			<div class="regi_name">{{$t('acTit4')}}</div>
			<el-form ref="form" :rules="rules" class="regi-from" :model="form">
				<el-form-item prop="password">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt9')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.password" type="password" placeholder=""></el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="code">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt3')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.code" placeholder=""></el-input>
						</div>
					</div>
				</el-form-item>
				<div class="regi_group chan_btn flex flex-ac flex-between">
					<el-button class="btn btn_reset"  size="large" type="primary" @click="resetForm('form')">{{$t('reset')}}
					</el-button>
					<el-button class="btn"  size="large" type="primary" @click="submitForm('form')">{{$t('save')}}</el-button>
				</div>
			</el-form>
			<!-- <div class="regi_tips">Copyright ©2024 Alcex All rights reserved.</div> -->
		</div>
	</div>
</template>

<script>
	import {
		jcgoogle
	} from '@/api/api/user'
	import RegiHead from '@/components/RegiHead.vue'
	export default {
		components: {
			RegiHead
		},
		data() {
			return {
				qrUrl: '',
				form: {
					code: '',
					password: ''
				},
				rules: {
					password: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t("chtip6"),
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: this.$t("chTxt3"),
						trigger: "blur"
					}]
				}
			};
		},
		mounted() {

		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						jcgoogle({
							code: this.form.code,
							secret: this.form.secret
						}).then(res => {
							if (res.code == 0) {
								this.$router.push('/admin')
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>
